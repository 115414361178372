import { GridItem, styled } from "styled-system/jsx"

export const Card = styled(GridItem, {
  base: {
    mx: { xlDown: "auto" },
    maxW: "340px",
    rounded: 20,
    overflow: "hidden",
    bgSize: "cover!",
    bgPosition: "center!",

    lg: {
      maxW: "572px",
    },
  },
  variants: {
    position: {
      left: {
        padding: "72px 76px 108px 28px",
        bg: "url(/textures/integration-left-bg.svg), #F4F6FF",

        lg: {
          padding: "132px 120px 180px 52px",
        },
      },
      right: {
        padding: "70px 24px 92px 80px",
        bg: "url(/textures/integration-right-bg.svg), #F4F6FF",

        lg: {
          padding: "132px 40px 120px 148px",
        },
      },
    },
  },
})

export const CardContent = styled("div", {
  base: {
    opacity: 0.8,
  },
})

export const CardTitle = styled("h4", {
  base: {
    fontSize: "16px",
    fontFamily: "heading",
    fontWeight: 700,
    lineHeight: "150%",
    mb: "24px",
    letterSpacing: "-0.304px",

    lg: {
      mb: "45px",
      fontSize: "30px",
      letterSpacing: "-0.57px",
    },
  },
})

export const CardBody = styled("p", {
  base: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: "-0.19px",
    opacity: 0.8,

    lg: {
      fontSize: "20px",
      letterSpacing: "-0.38px",
    },
  },
})
