import { parseBackground } from "lib/helpers"
import { IntegrationCards } from "schemas"
import { Container, HStack, styled } from "styled-system/jsx"

import {
  Card,
  CardBody,
  CardContent,
  CardTitle,
} from "./IntegrationCards.styles"

type Props = IntegrationCards

function IntegrationCards({
  titleLeft,
  titleRight,
  bodyLeft,
  bodyRight,
  backgroundColor1,
  backgroundColor2,
  backgroundStyle,
}: Props) {
  return (
    <styled.div
      className="quote"
      style={{
        background: parseBackground(
          backgroundColor1,
          backgroundColor2,
          backgroundStyle
        ),
      }}
      css={
        {
          // bg: "other.7",
        }
      }>
      <Container>
        <HStack
          css={{
            gap: "52px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "stretch",
          }}>
          <Card position="left">
            <CardContent>
              <CardTitle>{titleLeft}</CardTitle>
              <CardBody>{bodyLeft}</CardBody>
            </CardContent>
          </Card>
          <Card position="right">
            <CardContent>
              <CardTitle>{titleRight}</CardTitle>
              <CardBody>{bodyRight}</CardBody>
            </CardContent>
          </Card>
        </HStack>
      </Container>
    </styled.div>
  )
}

export default IntegrationCards
export type { Props as IntegrationCardsProps }
